import React from 'react';
// import '../styles/App.css.bak';
// import 'normalize.css';
import Banner from "../components/Banner";
import WhyChooseUs from "../components/WhyChooseUs";
import Fleet from "../components/Fleet";
import Services from "../components/Services";
import Reservation from "../components/Reservation";
import Footer from "../components/Footer";

function Landing() {
  return (
    <>
		<Banner />
		<WhyChooseUs />
		<Fleet />
		<Services />
		<Reservation />
		<Footer />
    </>
  );
}

export default Landing;
